"use client";
import { Paragraph } from "@cgc/ui/typography";
import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import clsx from "clsx";
import Image from "next/image";
import { useEffect, useState } from "react";
import { Simplify } from "type-fest";

/**
 * Props for `SpiritDescriptions`.
 */
export type SpiritDescriptionsProps =
  SliceComponentProps<Content.SpiritDescriptionsSlice>;

/**
 * Component for "SpiritDescriptions" Slices.
 */

const SpiritDescriptions = ({
  slice,
}: SpiritDescriptionsProps): JSX.Element => {
  const spirits = slice.primary.spirits;
  const [selectedSpirit, setSelectedSpirit] = useState<
    Simplify<Content.SpiritDescriptionsSliceDefaultPrimarySpiritsItem>
  >(spirits[0]);
  const [index, setIndex] = useState<number>(0);
  const [timerId, setTimerId] = useState(null);

  const numberOfSpirits = spirits.length;

  function showNextSpirit() {
    if (index + 1 >= numberOfSpirits) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  }

  useEffect(() => {
    setSelectedSpirit(spirits[index]);

    if (timerId) clearTimeout(timerId);

    const tempTimerId = setTimeout(() => showNextSpirit(), 6500);
    setTimerId(tempTimerId);
  }, [index]);

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className="flex w-full justify-center px-10 pb-10"
    >
      <div className="max-w-[1256px]">
        <div className="mb-4 flex flex-col gap-4">
          <Image
            src={selectedSpirit.image.url}
            alt={selectedSpirit.image.url}
            width={900}
            height={450}
            blurDataURL="/blurImage.png"
            placeholder="blur"
            className="aspect-video w-full rounded-lg object-cover"
          />
          <Paragraph
            size="p1"
            className="text-cgc-blue text-center md:text-left"
          >
            {selectedSpirit.copy}
          </Paragraph>
        </div>

        <div className="grid grid-cols-2 gap-3 md:grid-cols-4 md:gap-6">
          {slice.primary.spirits.map((spirit, index) => {
            return (
              <div onClick={() => setIndex(index)}>
                <Image
                  src={spirit.image.url}
                  alt={spirit.image.url}
                  width={400}
                  height={400}
                  blurDataURL="/blurImage.png"
                  placeholder="blur"
                  className={clsx(
                    `aspect-square w-72 rounded-lg object-cover ${selectedSpirit === spirit ? "border-cgc-blue border-4 shadow-lg" : ""}`,
                  )}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default SpiritDescriptions;
