"use client";
import { useLazyQuery } from "@apollo/client/react/hooks";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import TextInputField from "@/components/ui/Form/TextInputField";
import { Button } from "@cgc/ui/button";
import { Paragraph } from "@cgc/ui/typography";

import { GetDiscountByCodeQuery } from "@/data/helpers/promoCode/queries";
import { DiscountPrefix } from "@cgc/constants";
import { Spinner } from "@cgc/ui/spinner";
// import {
//   DISCOUNT_CODE_KEY,
//   setExpired,
//   setItem,
// } from "@/data/helpers/promoCode/hooks";

export const validationSchema = Yup.object({
  discountCode: Yup.string().required("Please enter discount code"),
});

type InitialValues = {
  discountCode: string;
};

function getDiscountCodeLink(code: string, boxes?: number): string {
  const isGiftCode = code.startsWith(`${DiscountPrefix.GIFT}-`);

  return isGiftCode
    ? `https://club.craftginclub.co.uk/pages/choose-frequency?code=${code}&boxes=${boxes}`
    : `https://club.craftginclub.co.uk/pages/sign-up?discount_code=${code}`;
}

export function DiscountForm() {
  const [getDiscounts, { loading }] = useLazyQuery(GetDiscountByCodeQuery);

  const onSubmit = async (
    { discountCode }: InitialValues,
    helpers: FormikHelpers<InitialValues>,
  ) => {
    getDiscounts({ variables: { code: discountCode } }).then(({ data }) => {
      if (data?.getDiscountByCode) {
        const url = getDiscountCodeLink(
          data?.getDiscountByCode.code,
          data?.getDiscountByCode.recurringCycleLimit,
        );
        // setItem(DISCOUNT_CODE_KEY, discountCode);
        // setExpired();
        window.location.replace(url);
      } else {
        helpers.setFieldError("discountCode", "Invalid discount code");
      }
    });
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ discountCode: "" }}
      validationSchema={validationSchema}
    >
      <Form className="space-y-4 px-5 text-center">
        <Paragraph size="sub2">Please enter your discount code below</Paragraph>
        <TextInputField name="discountCode" />
        <Button type="submit" color="secondary" className="px-8">
          {loading && (
            <Spinner
              className="m-0 mr-1 inline-block h-4 w-4 text-white"
              size="sm"
            />
          )}
          Apply
        </Button>
      </Form>
    </Formik>
  );
}
