"use client";
import { Paragraph } from "@cgc/ui/typography";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import { useState } from "react";

/**
 * Props for `FrequentlyAskedQuestions`.
 */
export type FrequentlyAskedQuestionsProps =
  SliceComponentProps<Content.FrequentlyAskedQuestionsSlice>;

/**
 * Component for "FrequentlyAskedQuestions" Slices.
 */
const FrequentlyAskedQuestions = ({
  slice,
}: FrequentlyAskedQuestionsProps): JSX.Element => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <div className="relative mx-auto w-full max-w-screen-xl">
        <Paragraph
          as="h2"
          style={{ color: slice.primary.title_colour }}
          className="text-balance text-center text-3xl font-bold md:text-6xl"
        >
          {slice.primary.title}
        </Paragraph>
        <div className={`flex flex-col gap-2 p-4`}>
          {slice.primary.questions_and_answers.map((QA) => {
            return (
              <AccordionPanel
                question={QA.question as string}
                answer={QA.answer as string}
                textColour={slice.primary.text_colour || "#000000"}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

function AccordionPanel({
  question,
  answer,
  textColour,
}: {
  question: string;
  answer: string;
  textColour: string;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <div
        key={question}
        title={question}
        className="z-10 py-2"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Paragraph
          style={{ color: textColour }}
          size="p1"
          className="relative z-0 flex items-center gap-4 hover:cursor-pointer"
          inline={false}
        >
          <Paragraph as="span" size="p1" className="w-full">
            {question}
          </Paragraph>
          <ChevronDownIcon
            className={`${isOpen === true ? "rotate-180" : "rotate-0"} h-6 w-6 transition duration-200`}
          />
        </Paragraph>
      </div>
      <div>
        <Paragraph
          size="p1"
          style={{ color: textColour }}
          className={`${isOpen ? "block py-4" : "hidden"}`}
        >
          {answer}
        </Paragraph>
      </div>
      <hr className="h-0.5 bg-gray-500" />
    </>
  );
}

export default FrequentlyAskedQuestions;
